<div id="block-agenda" class="content box">
    <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon>
    <div class="splitter"></div>

    
    <!-- <img src="../assets/img/categs/funcional.png" style="width: 52px; margin-bottom: 18px;"> -->
    <fa-icon [icon]="boxFAIconDouble" 
    style="font-size: 52px; margin-bottom: 18px;color: #111;"></fa-icon>

    <h4 class="title" style="font-family:'open sans';font-weight: 600;margin-top: 25px;">AGENDE SUA AULA</h4>
<!-- 
    <p class="txt-label" style="width: 100%;">
        Devido a <b style="color: #000">epidemia de COVID-19</b><br/>
        estamos agendando aulas<br/>
        de até <b style="color: #000">18 pessoas</b> 
    </p> -->

    <p class="txt-label" style="width: 100%;">
        
    </p>

    <button id="btn_goagenda" class="btn-splatter" routerLink="goagenda">AGENDAMENTO</button>

</div>
