import { Component, OnInit } from '@angular/core';
import { eClick } from '../services/entities';
import { HaylieService } from '../services/haylie.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private haylie: HaylieService) { }

  ngOnInit(): void {
  }

  goHaylie(p_param:string): void{

    switch (p_param) {
      case "regclick":

        var click = new eClick();

        this.haylie.send_click(click); 
        break;
    }

  }

}
