import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faEdit, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { eLead } from '../services/entities'
import { HaylieService } from '../services/haylie.service'
import { GsapService } from '../services/gsap.service'
import { observable } from 'rxjs';



@Component({
  selector: 'app-page-prehreg',
  templateUrl: './page-prehreg.component.html',
  styleUrls: ['./page-prehreg.component.css']
})
export class PagePrehregComponent implements OnInit {
  boxFAIcon = faEdit;
  boxFAIconResult = faPaperPlane;
  page_status: boolean = false;
  loading_status: boolean = false;
  response_status: boolean = false; 

  @ViewChild('prehreg_nick') frmnick:any;
  @ViewChild('prehreg_mail') frmmail:any;
  @ViewChild('prehreg_phone') frmphone:any;
  @ViewChild('prehreg_birth') frmbirth:any; 

  constructor(
    private route: ActivatedRoute,
    private haylieService: HaylieService,
    private router: Router, 
    private gsap: GsapService
  ) { }

  ngOnInit(): void {
    this.page_status = true;
  }

  goBack(): void { this.page_status = false; }

  goHaylie():void {
    //this.gsap.anima_trg("prehreg");

    var frmcheck = true;
    var obj = new eLead();
    obj.id = '0';

    console.log('running..');
    //duhflag - apply masks
    if (this.frmnick.nativeElement.value.length <= 3)
      { frmcheck = false; console.log('frmerror> missing nick'); }
    else  
      obj.name = this.frmnick.nativeElement.value;

    if (this.frmbirth.nativeElement.value.length >= 3)
      { frmcheck = false; console.log('frmerror> age error'); }
    else  
      obj.birth = this.frmbirth.nativeElement.value;

    if (this.frmmail.nativeElement.value.length <= 3 || this.frmmail.nativeElement.value.indexOf("@") <= 0)
      { frmcheck = false; console.log('frmerror> missing mail'); }
    else  
      obj.mail =  this.frmmail.nativeElement.value;

    if (this.frmphone.nativeElement.value.length < 9)
      { frmcheck = false; console.log('frmerror> missing phone'); }
    else  
      obj.phone =  this.frmphone.nativeElement.value;

      if (frmcheck) {
        this.gsap.anima_trg("prehreg");

        console.log('sending lead> ' + obj);
        this.haylieService.send_lead(obj);
        console.log('done.');

        
        setTimeout(() => {
          this.gsap.anima_trg("rocket");
          //this.gsap.anima_scrolltop();
          this.router.navigateByUrl("/");
        }, 7000);
      }
      else {
        this.gsap.anima_trg("blink_prehreg");
      }



  }

}
