import { Component, OnInit, ViewChild } from '@angular/core';
import { faCheckDouble, faClock, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router'


import { eAgenda, eClass } from '../services/entities'
import { GsapService } from '../services/gsap.service'
import { HaylieService } from '../services/haylie.service';
import { MorganaService } from '../services/morgana.service';

@Component({
  selector: 'app-page-agenda',
  templateUrl: './page-agenda.component.html',
  styleUrls: ['./page-agenda.component.css']
})
export class PageAgendaComponent implements OnInit {
  boxFAIcon = faCheckDouble;
  boxFAIconResult = faPaperPlane;
  page_status : boolean = false; 
  loading_status : boolean = false; 

  model_classes : any[] = [];
  model_agenda : any[] = [];
  model_timebox : any[] = [];
  frm_timeboxval = "";
  
  timebox_jiu : any[] = ["07:00","12:15","18:00", "19:30"];
  timebox_jiu2 : any[] = ["18:30","20:00"];
  timebox_jiu3 : any[] = ["09:00"];
  
  timebox_thay : any[] = ["07:00","09:00","19:00"];
  timebox_thay2 : any[] = ["08:00","20:00"];
  timebox_thay3 : any[] = ["12:30"];

  timebox_boxe : any[] = ["15:00","20:30","21:00"];
  timebox_boxe2 : any[] = ["07:00","19:00"];
  timebox_boxe3 : any[] = ["12:30"];

  listdays_timeset = "";
  listdays_timesetindex = "";
  listdays_thay_ready : any[] = [];
  listdays_thay = "";
  listdays_jiu_ready : any[] = [];
  listdays_jiu = "";
  listdays_boxe_ready : any[] = [];
  listdays_boxe = "";
  listdays_week : any[] = [];

  @ViewChild('agenda_skill') frmskill:any;
  @ViewChild('agenda_dateto') frmdateto:any;
  @ViewChild('agenda_time') frmtime:any;
  @ViewChild('agenda_padawan') frmpadawan:any;
  @ViewChild('agenda_dates') frmlistdates:any;

  constructor(private gsap: GsapService, 
              private haylie: HaylieService,
              private morgana: MorganaService,
              private router: Router) {  }

  ngOnInit(): void {
    this.page_status = true;
    this.get_agenda();
    this.haylie.get_classes("").then(this.catch_classes);
  }
  private catch_classes = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_classes = l_response.classes;

    //this.gsap.anima_trg('cronos', '');
    return this.model_classes;
  }

  get_agenda(): void {
    this.haylie.get_agenda().then(this.load_agenda);
  }
  private load_agenda = (p_response: any) =>  {
    let l_response = JSON.parse(p_response);
    console.log('response> ' + l_response.days);

    this.model_agenda = [];
    for (var d = 0; d < l_response.days.length; d++) {
      if (l_response.days[d].skill == "thay" && this.listdays_thay_ready.indexOf(l_response.days[d].dateshort) < 0) {
        this.listdays_thay += '<option label="' + l_response.days[d].dayofweek + '">' + l_response.days[d].date.substr(0, 10) + '</option>'
        this.listdays_thay_ready[d] = l_response.days[d].dateshort;
      }
      if (l_response.days[d].skill == "jiu" && this.listdays_jiu_ready.indexOf(l_response.days[d].dateshort) < 0) {
        this.listdays_jiu += '<option label="' + l_response.days[d].dayofweek + '">' + l_response.days[d].date.substr(0, 10) + '</option>'
        this.listdays_jiu_ready[d] = l_response.days[d].dateshort;
      }
      if (l_response.days[d].skill == "boxe" && this.listdays_boxe_ready.indexOf(l_response.days[d].dateshort) < 0) {
        this.listdays_boxe += '<option label="' + l_response.days[d].dayofweek + '">' + l_response.days[d].date.substr(0, 10) + '</option>'
        this.listdays_boxe_ready[d] = l_response.days[d].dateshort;
      }
      

      if (l_response.days[d].dateshort.length > 0 && 
        this.listdays_week.indexOf(l_response.days[d].dateshort) < 0) 
          this.listdays_week[d] = l_response.days[d].dayofweek;
      
    }

    return this.model_agenda;

  }
  select_change(p_select:string, p_val:string):void {

    if (p_select == "time") this.frm_timeboxval = p_val.replace(":", "");
    else {
      this.frm_timeboxval = "";

      if (p_select == "skill") {
          this.frmdateto.nativeElement.value = '';
          this.frmtime.nativeElement.value = '';

          console.log('_selected.' + p_select + '> ' + p_val);
          
          let listswitch = p_val == "jiu" ?  this.listdays_jiu_ready : (p_val == "thay" ?  this.listdays_thay_ready : this.listdays_boxe_ready);
          
          this.model_agenda = [];
          for (var d = 0; d < listswitch.length; d++) {
            if (listswitch[d] != null) 
                this.model_agenda.push({ dateshort: listswitch[d], dayofweek : this.listdays_week[d] });
          }
          
          this.listdays_timeset = p_val;
      }
      if (p_select == "dateto") {
        this.frmtime.nativeElement.value = '';
          console.log('_selected.' + p_select + '> ' + p_val);
          
          var dateformat = p_val.substr(6,4) + '-' + p_val.substr(3,2) + '-' + p_val.substr(0,2);
          var day = new Date(dateformat).getUTCDay();
          //var day = parseInt(p_val.substr(0,2));  
          var newIndex = [1, 3, 5].includes(day) ? "1" : ([2, 4].includes(day) ? "2" : "3");
          //this.listdays_timesetindex = newIndex;
          this.listdays_timesetindex = day.toString();

          console.log('_selected.day> ' + day);
      }
      this.timeboxreview2();
    }

  }
  timeboxreview2(): void {
  
    if (this.model_classes.length > 0) {

      var selectedDate = this.frmdateto.nativeElement.value;
      console.log('_selected.dateto.filter_value> ' + selectedDate);

      var year = selectedDate.substr(6,4);
  //    console.log('_selected.dateto.filter_value-y> ' + year);
      var month = selectedDate.substr(3,2);  
  //    console.log('_selected.dateto.filter_value-m> ' + month);
      var day = selectedDate.substr(0,2);  
  //    console.log('_selected.dateto.filter_value-d> ' + day);
      var l_datefilter = day + '/' + month + '/' + year;
      
      console.log('_selected.dateto.filter> ' + l_datefilter);
      
      this.model_timebox = [];
      var l_list = this.model_classes.filter(x => x.skill == this.listdays_timeset && x.day == this.morgana.getdayofweek(this.listdays_timesetindex));
      l_list.forEach(x => {
         if (x.alive == 1) this.model_timebox.push(x.time); 
      })
     
      
    }
    
  }
  goHaylie():void {
    //this.gsap.anima_trg("agendaset");

    var frmcheck = true;
    var obj = new eAgenda();

    if (this.frmskill.nativeElement.value.length <= 0)
    { frmcheck = false; console.log('frmerror> missing skill'); }
    else  
      obj.skill = this.frmskill.nativeElement.value;
    
    if (this.frmdateto.nativeElement.value.length <= 0)
    { frmcheck = false; console.log('frmerror> missing dateto'); }
    else  
      obj.dateto = this.frmdateto.nativeElement.value;
    
    if (this.frm_timeboxval.length <= 0)
    { frmcheck = false; console.log('frmerror> missing time'); }
    else  
      obj.time = this.frm_timeboxval;
    
    if (this.frmpadawan.nativeElement.value.length <= 0)
    { frmcheck = false; console.log('frmerror> missing padawan'); }
    else  
      obj.padawan = this.frmpadawan.nativeElement.value;


      
    if (frmcheck) {
      this.gsap.anima_trg("agendaset");

      console.log('sending agenda> ' + obj);
      this.haylie.send_agenda(obj);
      console.log('done.');

      setTimeout(() => {
        this.gsap.anima_trg("rocket");
        //this.gsap.anima_scrolltop();
        this.router.navigateByUrl("/");
      }, 7000);

    }
    else {
      this.gsap.anima_trg("blink_agenda");
    }

  }

}
