<div id="fakelayer_sidebar" class="modal-bg" (click)="sidebar_trg('close')"
	style="background: transparent; z-index: 9999999; display: none;"></div>
<div id="mastersidebar" class="sidebar">
	<a (click)="sidebar_trg('close')"></a>
	
	<!-- <img class="logo" src="../assets/img/logo/nf_w.png" style="width: 160px;" /> -->
	<ul>
		<li><a href="#" (click)="goPage('goprehreg')">PRÉ-CADASTRO</a></li>
        <li><a href="#" (click)="goPage('goagenda')">AGENDE SUA AULA</a></li>
		<li><a href="http://octa.academianewfight.com.br/" target="_blank"
			 (click)="goPage('goocta')">PORTAL DO ALUNO</a></li>
		<li><a class="off">PLANOS</a></li>
		<li><a href="#" (click)="goPage('goclasses')">AULAS</a></li>
		<li><a href="#" (click)="goPage('gogallery')">GALERIA</a></li>
		<li><a class="off">EVENTOS</a></li>
		<li><a href="#" (click)="goPage('gotalk')">CONTATO</a></li>
	</ul>
	
	<img class="logo" src="../assets/img/logo/almeidajj.png" style="width: 160px;" />
	<br/>
	<!-- <img class="logo" src="../assets/img/logo/revirablackteam.png" style="width: 160px;" /> -->
	
	<a href="http://octa.academianewfight.com.br/" target="_blank"
		style="position: absolute; bottom: 9px; z-index: 99999999999; display: block; 
				left: 50%; margin-left: -18px; opacity: .9;">
        <img class="octasvg" src="../assets/svg/octagon.svg" 
					style="opacity: 1; width: 36px; margin-top: 30px;" />
    </a> 

</div>