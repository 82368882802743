<div id="block-gallery" class="content box">
    <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon>
    <div class="splitter"></div>

    <div class="window">
        <ul *ngIf="model_pics.length > 0" style="padding-bottom: 30px;">
            <li *ngFor="let p of model_pics">
                <a *ngIf="p.name != 'init'" class="col-1-4"> 
                    <img src="{{ 'http://haylie.bosswebapps.net/gallery/' + p.name }}" width="98%" />
                </a>
                
            </li>
        </ul>
        <ul *ngIf="model_pics.length <= 0">
            <li><img src="../assets/img/pictures/001.jpeg"></li>
            <li><img src="../assets/img/pictures/002.jpeg"></li>
            <li><img src="../assets/img/pictures/003.jpeg"></li>
            <!-- <li><img src="../assets/img/pictures/004.jpeg"></li>
            <li><img src="../assets/img/pictures/005.jpeg"></li>
            <li><img src="../assets/img/pictures/006.jpeg"></li>
            <li><img src="../assets/img/pictures/007.jpeg"></li> -->
        </ul>
    </div>

    <button id="btn_gogallery" class="btn-splatter" routerLink="gogallery">GALERIA</button>

</div>