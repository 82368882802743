<div *ngIf="page_status">
    <div class="modal-bg"></div>
    <div id="page_agenda" class="modal">
        <img class="logo" src="../assets/img/logo/nftxt_on.png" />
        <a class="close" routerLink="/">{{ "< VOLTAR" }}</a>
        <br/><br/><br/>

        <div *ngIf="!loading_status">
            <fa-icon [icon]="boxFAIcon" class="pageicon"></fa-icon>
            <h1 class="title">AGENDE SUA AULA</h1>
            <p class="sub">
                A grade de aulas pode sofrer alterações<br/>
                conforme as fases de restrição<br/>
                impostas pelo estado        
            </p>
            <br/>
            <div class="frm">
                <!--- skill --->
                <input #agenda_skill class="inputtxt" type="text" 
                     placeholder="Aula" list="agenda_skills"
                    (change)="select_change('skill', agenda_skill.value)"
                    (keydown)="$event.preventDefault()"
                    (focus)="agenda_skill.value=''"
                    (click)="agenda_skill.value=''" />
                <datalist id="agenda_skills">
                    <option value="jiu">Jiu Jitsu</option>
                    <option value="thay">Muay Thay</option>
                    <option value="boxe">Boxe</option>
                </datalist>
                <!--- dateto --->
                <input #agenda_dateto class="inputtxt" type="text"  
                    data-placeholder="Dia" required aria-required="true"
                    name="dateto" placeholder="Dia" list="agenda_dates" 
                    (change)="select_change('dateto', agenda_dateto.value)"
                    (keydown)="$event.preventDefault()"
                    (focus)="agenda_dateto.value='';"
                    (click)="agenda_dateto.value='';" />
                <datalist id="agenda_dates">
                    <option *ngFor="let d of model_agenda" [value]="d.dateshort">{{d.dayofweek}}</option>
                </datalist>
                <!--- timebox --->
                <input #agenda_time class="inputtxt" type="text" 
                     placeholder="Hora" list="agenda_timebox"
                     (change)="select_change('time', agenda_time.value)"
                     (keydown)="$event.preventDefault()"
                     (focus)="agenda_time.value='';"
                     (click)="agenda_time.value='';"
                      />
                <datalist id="agenda_timebox">
                    <option *ngFor="let t of model_timebox" [value]="t">{{ t }}</option>
                </datalist>
               
                <input class="inputtxt" type="text" #agenda_padawan name="padawan" value="" placeholder="Nome" />
                
                <button class="btn-splatter" (click)="goHaylie()"
                    style="top: 63px; font-size: 12px; letter-spacing: 3px;">
                    AGENDAR</button>
                <a href="#" class="btnReturn" routerLink="/">
                    VOLTAR</a>

            </div>
        </div>
        <div id="agenda_loading" class="loading" style="opacity: 0; z-index: -1;">
            <fa-icon [icon]="boxFAIconResult" class="pageicon" style="top: 0;"></fa-icon>
            <br/>
            <h1 class="title">AGENDAMENTO ENVIADO</h1>
            <br/>
            <p class="sub">Agendamento efetuado com <b style="color: #000">sucesso</b></p>
            <!-- <p class="sub">Entraremos em contato<br/> para confirmar sua <br/>aula experimental</p>
            <p class="sub">Obrigado<br/><br/><br/></p> -->
            <br/>
            <div style="float: left; position: relative; top: 90px; z-index: 999999999999;
                                text-align: center; width: 100%;">
                <img src="../assets/img/gif/loading.gif" />
            </div>

        </div>

    </div>
</div>