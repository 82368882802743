import { Component, OnInit } from '@angular/core';

import { GsapService } from '../services/gsap.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private gsap: GsapService) { }

  ngOnInit(): void {
  }
  sidebar_trg(p_action:string):void {
    this.gsap.anima_trg("sidebar_" + p_action);
  }
}
