<!-- area51 | admin . ref

<div id="header" style="display: none;">
    <div class="content" style="overflow: hidden;height: 52px;margin: 0;float: left;"> 
        <img id="logo_inline" class="logo" src="../assets/img/logo/nftxt_on.png" 
            style="opacity: 1; width: 180px; margin-left: 2.5%" />

            <a href="#" id="trg_sidebar" style="display: none"><i class="fas fa-ellipsis-v"></i></a>
    </div>
</div> -->

<!-- mobile-v1 . based --->
<div id="masterheader" class="header">
	<img id="logo_inline" class="logo" src="../assets/img/logo/nftxt_on.png" style="opacity: 0" />
	
    <a (click)="sidebar_trg('open')" id="trg_nav">
        <span id="nav1">_</span><span id="nav2">_</span><span id="nav3">_</span>
    </a>
</div>	