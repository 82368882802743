import { Injectable } from '@angular/core';
import { gsap, TimelineMax, TweenLite, Power4, Elastic, Linear } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

@Injectable({
  providedIn: 'root'
})
export class GsapService {
  //private eBody = "app-root";
  private eSidebar = '#mastersidebar'

  private eNav1 ="#trg_nav span#nav1";
	private eNav2 = "#trg_nav span#nav2";
	private eNav3 = "#trg_nav span#nav3";

  private eTeaserBox = '#teaserbox';
  private eTeaserLogo = '#teaserlogo';
  private eTeaserLogoOn = '#teaserlogo_on';
  private eTeaserLogoOn2 = '#teaserlogo_on2';
  private eTeaserLogoOff = '#teaserlogo_off';
  private eTeaserLogoOff2 = '#teaserlogo_off2';
  private eTeaserLogoOff3 = '#teaserlogo_off3';

  private eTeaserTags = '.teaser #tags';
  private eTeaserTagJiu = '#teaserlijiu';
  private eTeaserTagThay = '#teaserlithay';
  private eTeaserTagBoxe = '#teaserliboxe';

  private eTeaserSlogan = '.teaser .slogan';
  private eTeaserSloganTxt = '.teaser .slogan span';

  private eCrossMain = '#maincross';
  private eTeaserSocials = '#block-init .socials';

  private eBlockAbout = '#block-about';
  private eBlockAgenda = '#block-agenda';
  private eBlockClasses = '#block-classes';
  private eBlockGallery = '#block-gallery';
  private eBlockMap = '#block-map';
  private eBlockOcta = '#block-octa';
  private eBlockBWA = '#block-bwa';
  private eBlockAdSense = '#block-adsense';
  
  private eLoadingPrehreg = '#prehreg_loading';
  private eLoadingAgenda = '#agenda_loading';

  constructor() { }

  public init_setup(){
    TweenLite.to(this.eTeaserBox, .1, { height: "600px", ease: Linear.easeNone });
    
		TweenLite.to(this.eTeaserLogoOff3, .1, { y: -61, opacity: 0, ease: Linear.easeNone });
		TweenLite.to(this.eNav1, .1, { y: -50, opacity: 0, ease: Linear.easeNone });
		TweenLite.to(this.eNav2, .1, { y: -50, opacity: 0, ease: Linear.easeNone });
		TweenLite.to(this.eNav3, .1, { y: -50, opacity: 0, ease: Linear.easeNone });
    TweenLite.to(this.eTeaserTagJiu, .1, { y: 9, opacity: 0, ease: Linear.easeNone });
    TweenLite.to(this.eTeaserTagThay, .1, { y: 9, opacity: 0, ease: Linear.easeNone });
    TweenLite.to(this.eTeaserTagBoxe, .1, { y: 9, opacity: 0, ease: Linear.easeNone });

    TweenLite.to(this.eBlockAbout, .1, { opacity: 0, y: 18, ease: Linear.easeIn });
    TweenLite.to(this.eBlockAgenda, .1, { opacity: 0, y: 18, ease: Linear.easeIn });
    TweenLite.to(this.eBlockClasses, .1, { opacity: 0, y: 18, ease: Linear.easeIn });
    TweenLite.to(this.eBlockGallery, .1, { opacity: 0, y: 18, ease: Linear.easeIn });
    TweenLite.to(this.eBlockMap, .1, { opacity: 0, y: 18, ease: Linear.easeIn });
    TweenLite.to(this.eBlockOcta, .1, { opacity: 0, y: 18, ease: Linear.easeIn });
    TweenLite.to(this.eBlockAdSense, .1, { opacity: 0, y: 18, ease: Linear.easeIn });
    TweenLite.to(this.eBlockBWA, .1, { opacity: 0, y: 18, ease: Linear.easeIn });

  }

  public anima_full() {
    var tlInit = new TimelineMax();
    tlInit.add(TweenLite.to(this.eTeaserLogoOff3, 1, { opacity: .3, y: 0, ease: Power4.easeIn }), 0);
		
		tlInit.add(TweenLite.to(this.eTeaserLogoOff, .5, { opacity: .5, y: -15, x: -15, ease: Linear.easeIn }), 1);
		tlInit.add(TweenLite.to(this.eTeaserLogoOn, .5, { opacity: .3, y: -20, x: -13, ease: Linear.easeIn }), 1);
		tlInit.add(TweenLite.to(this.eTeaserLogoOn2, .5, { opacity: .7, y: 10, x: -10, ease: Linear.easeIn }), 1);
		
		tlInit.add(TweenLite.to(this.eTeaserLogoOff, .2, { opacity: 0, y: 0, x: 0, ease: Linear.easeOut }), 1.7);
		tlInit.add(TweenLite.to(this.eTeaserLogoOn, .2, { opacity: 0, y: 0, x: 0, ease: Linear.easeOut }), 1.7);
		tlInit.add(TweenLite.to(this.eTeaserLogoOn2, .2, { opacity: 0, y: 0, x: 0, ease: Linear.easeOut }), 1.7);
		
		tlInit.add(TweenLite.to(this.eTeaserLogo, .7, { opacity: 1, y: 0, x: 0, ease: Linear.easeOut }), 1.9);
		tlInit.add(TweenLite.to(this.eTeaserLogoOff3, .4, { opacity: 0, y: 0, x: 0, ease: Linear.easeNone }), 2.5);

    tlInit.add(TweenLite.to(this.eTeaserTagJiu, .5, { opacity: 1, y: 0, ease: Linear.easeInOut }), 2.7);
    tlInit.add(TweenLite.to(this.eTeaserTagThay, .5, { opacity: 1, y: 0, ease: Linear.easeInOut }), 2.9);
    tlInit.add(TweenLite.to(this.eTeaserTagBoxe, .5, { opacity: 1, y: 0, ease: Linear.easeInOut }), 3.1);

    tlInit.add(TweenLite.to(this.eTeaserBox, .7, { height: "250px", ease: Linear.easeNone }), 4.1);
    tlInit.add(TweenLite.to(this.eTeaserTags, .7, { height: 0, ease: Linear.easeNone }), 4.1);
    tlInit.add(TweenLite.to(this.eTeaserSlogan, .7, { opacity: 1, ease: Linear.easeNone }), 4.1);
    tlInit.add(TweenLite.to(this.eTeaserSloganTxt, .7, { opacity: 1, ease: Linear.easeNone }), 4.1);
    tlInit.add(TweenLite.to(this.eTeaserSlogan, .7, { color: "#fff", ease: Linear.easeNone }), 5.2);
    
    tlInit.add(TweenLite.to(this.eCrossMain, 2.7, { opacity: 1, width: "90px", ease: Linear.easeNone }), 4.9); 
    
    tlInit.add(TweenLite.to(this.eTeaserSocials, .4, { opacity: 1, ease: Linear.easeNone }), 6.1);
    
    tlInit.add(TweenLite.to(this.eNav1, .9, { opacity: 1, y: 16, ease: Linear.easeInOut }), 6.4);
		tlInit.add(TweenLite.to(this.eNav2, .5, { opacity: 1, y: 8, ease: Linear.easeIn }), 6.6);
		tlInit.add(TweenLite.to(this.eNav3, .5, { opacity: 1, y: 0, ease: Linear.easeIn }), 6.8);

    tlInit.add(TweenLite.to(this.eBlockAbout, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 6.4);
    tlInit.add(TweenLite.to(this.eBlockAgenda, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 6.6);
    tlInit.add(TweenLite.to(this.eBlockClasses, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 6.8);
    tlInit.add(TweenLite.to(this.eBlockGallery, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 7);
    tlInit.add(TweenLite.to(this.eBlockMap, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 7.2);
    tlInit.add(TweenLite.to(this.eBlockOcta, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 7.4);
    tlInit.add(TweenLite.to(this.eBlockAdSense, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 7.6);
    tlInit.add(TweenLite.to(this.eBlockBWA, .4, { opacity: 1, y: 0, ease: Linear.easeIn }), 7.9);

    tlInit.play();
    
  }
  public anima_trg(p_which:string, p_currentstate:number=0) {
    switch(p_which) {
      case "prehreg": 
        TweenLite.to(this.eLoadingPrehreg, .9, { zIndex: 9, opacity: 1, ease: Linear.easeNone });
        break;

      case "agendaset": 
        TweenLite.to(this.eLoadingAgenda, .9, { zIndex: 9, opacity: 1, ease: Linear.easeNone });
        break;
      
        
      case "scrollto": 
        //window.scrollTo(0,0);
        TweenLite.to(window, 1, { scrollTo: { y: p_currentstate } });
        break;

      case "sidebar_close":
        TweenLite.to(this.eSidebar, .2, { right: "-101%", ease: Linear.easeNone });
        TweenLite.to("#fakelayer_sidebar", .2, { display: "none", ease: Linear.easeNone });
        break;

      case "sidebar_open":
        TweenLite.to(this.eSidebar, .2, { right: "0", ease: Linear.easeNone });
        TweenLite.to("#fakelayer_sidebar", .2, { display: "block", ease: Linear.easeNone });
        break;

      case "blink_prehreg":
        TweenLite.to('#page_prehreg .frm .inputtxt', .4, { borderBottom: "1px solid #ff0000", ease: Linear.easeNone }); 
        setTimeout(() => {
          TweenLite.to('#page_prehreg .frm .inputtxt', .4, { borderBottom: "1px solid #999", ease: Linear.easeNone });
        }, 3000);
        break;

      case "blink_agenda":
        TweenLite.to('#page_agenda .frm .inputtxt', .4, { borderBottom: "1px solid #ff0000", ease: Linear.easeNone }); 
        setTimeout(() => {
          TweenLite.to('#page_agenda .frm .inputtxt', .4, { borderBottom: "1px solid #999", ease: Linear.easeNone });
        }, 3000);
        break;
    }
  }
  public anima_scrolltop() {
    var tlScroll = new TimelineMax();
    tlScroll.add(TweenLite.to(document, .7, { scrollTo: { y: 0 } }), .1);

  }
  public anima_magic(p_target:string, p_magic:string) {
    //var tlScroll = new TimelineMax();
    //tlScroll.add(TweenLite.to(document, .7, { scrollTo: { y: 0 } }), .1);
    switch(p_magic) {
      case "fadeout": 
        TweenLite.to(this.eLoadingPrehreg, .9, { zIndex: 9, opacity: 1, ease: Linear.easeNone });
        break;

      case "vanish": 
        TweenLite.to(this.eLoadingPrehreg, .1, { opacity: 0, ease: Linear.easeNone });
        TweenLite.to(this.eLoadingPrehreg, .1, { display: 'none', opacity: 1, ease: Linear.easeNone });
        break;
    }

  }
  public agenda_timebox(p_action:string) {
    switch(p_action) {
      case "reset": 
        this.anima_magic('#page_agenda .frm .select_time', 'vanish');

        //TweenLite.to(this.eLoadingPrehreg, .9, { zIndex: 9, opacity: 1, ease: Linear.easeNone });
        break;
    }

  }

}
