export class eResponse {
    Sucess: boolean = false;
    Message: string = '';
    Value: ePicture[] = []; 
}

export class ePicture {
    id: string = '';
    dateins: string = '';
    name: string = '';
    alive: number = 1;
    _file_alive: number = 1;
}

export class eLead {
    id: string = '';
    birth: string = '';
    name: string = '';
    mail: string = '';
    phone: string = '';
}
export class eAgenda {
    id: string = '';
    padawan: string = '';
    skill: string = '';
    dateto: string = '';
    time: string = '';
    status: number = 1;
    device: number = 2;
}
export class eClass {
    id: string = '';
    skill: string = '';
    day: string = '';
    time: string = '';
    alive: number = 1;
    _count: number = 0;
}
export class eClick {
    id: string = '';
    dateins: string = '';
    tag: string = '';
    alive: number = 1;
    _count: number = 0;
}