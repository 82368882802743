import { Component, OnInit } from '@angular/core';

import { faCamera } from '@fortawesome/free-solid-svg-icons'

import { HaylieService } from '../services/haylie.service'
import { ePicture } from '../services/entities'

@Component({
  selector: 'app-page-gallery',
  templateUrl: './page-gallery.component.html',
  styleUrls: ['./page-gallery.component.css']
})
export class PageGalleryComponent implements OnInit {
  boxFAIcon = faCamera;
  page_status: boolean = false;
  model_pics: ePicture[] = [];
  
  constructor(private haylie: HaylieService,) { 
    
  }

  ngOnInit(): void {
    this.get_gallery();
    this.page_status = true;
  }
  get_gallery(): void {
    this.haylie.get_gallery().then(this.load_gallery);
  }
  private load_gallery = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    //var jobj = JSON.parse(l_response);
    //console.log('response> ' + l_response.pictures[1].name);
    this.model_pics = l_response.pictures;
    return this.model_pics;
   
  }
  goBack():void {
    this.page_status = false;
  }
  

}
