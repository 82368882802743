import { Component, OnInit } from '@angular/core';
import { faClipboardCheck, faClock, faList, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { eClass } from '../services/entities';
import { HaylieService } from '../services/haylie.service';

@Component({
  selector: 'app-block-classes',
  templateUrl: './block-classes.component.html',
  styleUrls: ['./block-classes.component.css']
})
export class BlockClassesComponent implements OnInit {
  boxFAIcon = faList;
  boxFAIconMain = faClock;
  model_classes : any[] = [];
  model_today : any[] = [];
  
  constructor(private haylie: HaylieService) { }

  ngOnInit(): void {
    this.haylie.get_classes("").then(this.catch_classes);
    
    this.haylie.get_classestoday("").then(this.catch_today);
  }
  private catch_classes = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_classes = l_response.classes;

    return this.model_classes;
  }
  private catch_today = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_today = l_response.classes;

    return this.model_today;
  }
}
