<div id="block-classes" class="content box">
    <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon>
    <div class="splitter"></div>

    
    <!-- <img src="../assets/img/categs/funcional.png" 
        style="width: 52px; margin-bottom: 9px;">  -->
    <fa-icon [icon]="boxFAIconMain" 
        style="font-size: 52px; margin-bottom: 18px;color: #111;"></fa-icon>

    <h4 class="title" style="font-family:'open sans';font-weight: 600;margin-top: 25px;">HORÁRIOS</h4>

    <p class="txt-label" style="width: 100%;line-height: 21px;">
        Confira nossa grade de aulas<br/>
        <b style="color: #000">{{model_classes.length}} aulas</b> por semana<br/>
        <b style="color: #000" *ngIf="model_today.length > 0">{{model_today.length}} aulas</b> hoje
    </p>

    <button id="btn_goclasses" class="btn-splatter" routerLink="goclasses">VER TODOS</button>

</div>
