import { Component, OnInit } from '@angular/core';
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-block-adsense',
  templateUrl: './block-adsense.component.html',
  styleUrls: ['./block-adsense.component.css']
})
export class BlockAdsenseComponent implements OnInit {
  boxFAIcon = faSatelliteDish;

  constructor() { }

  ngOnInit(): void {
  }

}
