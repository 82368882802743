<div id="box_cross" class="content box" 
        style="background: #111; opacity: 1; margin: 0; padding-bottom: 9px; width: 100%; border-radius: 0;">
    <br/>
    <img id="maincross" src="../assets/img/categs/newfight-cross-white.png" 
          style="width: 160px; opacity: 0; margin: 3px 0 16px 0;" />
    <br/>

    
<div id="box_cross" class="content box" 
style="background: #111; opacity: 1; margin: 0; padding-bottom: 9px; width: 100%; border-radius: 0;">
<br/>

  <div class="content" style="background: #fff;">
    <!-- <h1 style="font-size: 21px;font-family:'open sans';float:left;"><br/><br/><br/>  
      Receba os planos e horários no seu celular.</h1> -->
    <p class="txt-label" 
    style="float: left; color: #666; font-size: 12px;     line-height: 18px;font-family:'open sans'; margin: 21px 9px 18px;">
      <b>
        <br/>
        Receba os planos e horários agora mesmo no celular
        <br/>
        Clique aqui e fale com um atendente
      </b>
    </p>
    <button class="btn-splatter">
    <a target="_blank" href="https://api.whatsapp.com/send?phone=+5511976626886&text=Olá, gostaria de obter informações sobre as aulas de lutas da Academia New Fit %26 Fight, a melhor academia de lutas da região" >
      
    
    <img style="width: 27px;margin: -7px 3px" src="../assets/img/social/whatsapp-white.png" />
      FALE CONOSCO</a></button>

  </div>


</div>


  
    <div id="block-about" class="content box">
        <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon>
        <div class="splitter"></div>
        <p class="intro">
            Buscando <b style="color: #000">qualidade de vida</b> por meio das <b style="color: #000">artes marciais</b>, <br/>
            a <b style="color: #000">New Fight</b> chega com um conceito único onde atenderá 
            todo tipo de público, desde o competidor ao aluno que busca uma rotina mais saudável, 
            ou para aqueles que visam, também, fins estéticos. <br/>
            Com professores altamente capacitados, 
            carregando em seus currículos, títulos importantes e experiência; <br/>
            Além de um aconchegante, limpo, moderno e familiar ambiente de treinamento o caminho certo para você é a NF.<br/>
            <b style="color: #000">Venha ajudar a construir essa família</b><br/>
        </p>
  
        
        <div class="">
          <h1 style="font-size: 21px;font-family:'open sans';float:left;"><br/><br/><br/>  
            Receba os planos e horários no seu celular.</h1>
          <p class="txt-label" 
          style="float: left; color: #666; font-size: 12px;     line-height: 18px;font-family:'open sans'; margin: 21px 9px 18px;">
            <b>
              <br/>
              Faça o pré cadastro e em instantes entraremos em contato 
              com as insformações.
            </b>
          </p>
          <button class="btn-splatter" (click)="goPage()">PRÉ-CADASTRO</button>
        
        </div>
        <!-- <div class="">
          <p class="txt-label" style="float: left; color: #666; font-size: 12px;     line-height: 18px;font-family:'open sans'; margin: 36px 9px 18px;">
            Faça seu cadastro sem nenhum tipo de compromisso e <b>garanta seu desconto</b>
          </p>
          <button class="btn-splatter" (click)="goPage()">PRÉ-CADASTRO</button>
        
        </div> -->
        
    </div>
        
    <img src="../assets/img/pictures/002.jpg" 
            style="max-width: 420px; width: 95%; margin: 3px 2.5% 9px;" /> 

            <img src="../assets/img/logo/w/nf_full.png" 
            style="max-width: 180px; width: 95%; margin: 3px 2.5% 9px;" /> 
  
  </div>