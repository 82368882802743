<div *ngIf="page_status">
    <div class="modal-bg"></div>
    <div id="page_agenda" class="modal">
        <img class="logo" src="../assets/img/logo/nftxt_on.png" />
        <a class="close" routerLink="/">{{ "< VOLTAR" }}</a>
        <br/><br/><br/>

        <div *ngIf="!loading_status">
            <fa-icon [icon]="boxFAIcon" class="pageicon"></fa-icon>
            <h1 class="title">AULAS</h1>
            <p class="sub">
                Selectione um dia da semana<br/>
                para recarregar os horários      
            </p>
            <br/>
            <div id="frmsearch" class="frm" style="min-height: 34px;">
                <!--- skill --->
                <!-- <input #search_skill class="inputtxt" type="text" 
                    placeholder="Categoria" list="search_skills" 
                    value="{{ model_search_skill }}" 
                    (change)="select_change('search', search_skill.value)"
                    (focus)="search_skill.value='';"
                    (click)="search_skill.value='';"
                 />
                <datalist id="search_skills">
                    <option value="jiu">Jiu Jitsu</option>
                    <option value="thay">Muay Thay</option>
                    <option value="boxe">Boxe</option>
                </datalist> -->
                <!--- day --->
                <input #search_dateto class="inputtxt" type="text"  
                    data-placeholder="Dia" required aria-required="true"
                    name="dateto" placeholder="Dia" list="search_days" 
                    value="{{ model_search_day }}"
                    (change)="select_change('searchday', search_dateto.value)"
                    (focus)="search_dateto.value='';"
                    (click)="search_dateto.value='';" />
                <datalist id="search_days">
                    <option *ngFor="let d of model_base_days" [value]="d">{{d}}</option>
                </datalist>
            </div>

            <div class="window">
                <div *ngIf="model_classes.length <= 0" class="empty"><br/><br/><br/>
                    <p style="text-align: center;color: #ccc;">Nenhum registro encontrado</p>
                </div>
                <ul *ngIf="model_classes.length > 0"
                    style="padding-bottom: 90px;opacity: 1;">
                    <li *ngFor="let skill of model_base_skills" id="phome-liclassbox" class="content box alivebg"  
                        style="opacity: 1;padding: 0;margin: 9px 0; top: 0;float: left;padding-left: 41.2%;" >
                        <div *ngIf="model_search_day.length > 0">
                            <img class="boxicon" 
                                style="width: 34px;top: 9px; left:18px; position: absolute;"
                                    src="{{ '../assets/img/categs/' + skill + '.png' }}" />
                            <h4>{{skill}}</h4>
                            <!-- <p style="
                                width: auto;
                                padding: 3px 18%;
                                border: 2px solid #333;
                                margin-left: -18%;
                            ">HORÁRIOS</p> -->
                            <div>
                                <div *ngFor="let reg of filterClasses(skill, search_dateto.value)" 
                                    id="planbox{{ reg.id }}" class="alertbox" 
                                    style="position: relative;padding: 0">
                                <!-- <h4 style="font-weight: 600; padding: 18px 0 9px 0;display: inline-block; float: left;">
                                    {{ reg.skill }}</h4> -->
                                <p style="line-height: 25px;">
                                    <!-- {{ reg.day  }}  -->
                                    <b>{{ reg.time  }}</b></p>

                                </div>
                            </div>
                        </div>
                    </li>
                    <li style="height: 90px;float: left; width: 90%;"></li>
                </ul>
            </div>

        </div>
    </div>
</div>