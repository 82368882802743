
<app-header></app-header>

<app-sidebar></app-sidebar>


<div id="block-init" class="content" style="margin: 0; width: 100%; position: relative; top: -0.9px;">
  <div id="teaserbox" class="teaser"> 
		<img id="teaserlogo_off3" class="logo_off3" src="../assets/img/logo/nf_off.png" />
		<img id="teaserlogo_off" class="logo_off" src="../assets/img/logo/nf_off.png" />
		<img id="teaserlogo_off2" class="logo_off2" src="../assets/img/logo/nf_off.png" />
		<img id="teaserlogo_on2" class="logo_on2" src="../assets/img/logo/nf_on.png" />
		<img id="teaserlogo_on" class="logo_on" src="../assets/img/logo/nf_on.png" />
		<img id="teaserlogo" class="logo" src="../assets/img/logo/nf_full.png" />
	
    <p class="logofakelimit" style="position: relative; float: left; width: 100%; height: 9px; margin-top: 135px;"></p> 
    <br/>
    <ul id="tags">
        <li id="teaserlijiu"><img src="../assets/img/categs/jiu.png" /><p>JIU JITSU</p></li>
        <li id="teaserlithay"><img src="../assets/img/categs/thay.png" /><p>MUAY THAY</p></li>
        <li id="teaserliboxe"><img src="../assets/img/categs/boxe.png" /><p>BOXE</p></li>
    </ul>
    <p class="slogan"><span>ARTES MARCIAIS & QUALIDADE DE VIDA</span></p>
        
    <div style="text-align: center; position: relative; top: 52px;">
      <ul class="socials">
        <li style="float: left;">
          <a target="_blank" href="https://www.facebook.com/Academia-New-Fight-677449039130834">
            <img style="width: 25px;" src="../assets/img/social/facebook-f.png" /></a>
        </li>
        <li>
          <a target="_blank" href="https://www.instagram.com/Academianewfitefight">
            <img style="width: 26px;" src="../assets/img/social/instagram-square.png" /></a>
        </li>
        <li style="float: right;">
          <a target="_blank" 
              href="https://api.whatsapp.com/send?phone=+5511976626886&text=Olá, gostaria de obter informações sobre as aulas de lutas da Academia New Fit %26 Fight, a melhor academia de lutas da região"
              (click)="goHaylie('regclick')">
            <img style="width: 27px;" src="../assets/img/social/whatsapp-black.png" /></a>
        </li>
      </ul>
    </div>

	</div>
</div>

<app-block-lead></app-block-lead>

<app-block-agenda></app-block-agenda>

<app-block-classes></app-block-classes>
<!-- 
< app-block-socials></app-block-socials >
-->
<app-block-gallery></app-block-gallery>

<app-block-map></app-block-map>

<img src="../assets/img/pictures/005.jpg" 
            style="max-width: 420px; width: 95%; margin: 3px 2.5% 9px;" /> 

<app-block-octa></app-block-octa>

<img src="../assets/img/pictures/006.jpg" 
            style="max-width: 420px; width: 95%; margin: 3px 2.5% 9px;" /> 

<app-block-adsense></app-block-adsense>

<app-block-bwa></app-block-bwa>

<img src="../assets/img/pictures/ninjas.jpeg" 
            style="max-width: 420px; width: 95%; margin: 3px 2.5% 9px;" /> 

<app-footer></app-footer>



<router-outlet></router-outlet>