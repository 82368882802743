  <div id="masterfooter" class="footer">
	<div class="content">
        <img id="logo_inline" class="logo" src="../assets/img/logo/w/nftxt_on.png" style="opacity: 1" />
		
		<a href="http://octa.academianewfight.com.br/" target="_blank">
			<img class="octasvg" src="../assets/img/octa/octa-white.png" 
					style="opacity: 1; width: 36px;float: right;margin: 7px;" />
		</a>
		<br/>

		<p style="float: left;font-size: 16px;letter-spacing: 4.2px;margin: 36px 9px 0;color: #ccc;">
			#everydayanewfight</p>

		<p style="float: left;font-size: 12px;letter-spacing: 4.2px;margin: 36px 9px;color: #ccc;
				text-align: left;font-weight: 100;font-family: open sans;">
			Venha fazer uma<br/>aula experimental</p>


		<div style="text-align: center; position: relative; top: 9px; float: right">
			<ul class="socials">
				<!-- <li style="float: left;">
				<a target="_blank" href="https://www.facebook.com/Academia-New-Fight-677449039130834">
					<img style="width: 25px;" src="../assets/img/social/facebook-f.png" /></a>
				</li>
				<li>
				<a target="_blank" href="https://www.instagram.com/Academianewfitefight">
					<img style="width: 26px;" src="../assets/img/social/instagram-square.png" /></a>
				</li> -->
				<li style="float: right;">
				<a target="_blank" 
					href="https://api.whatsapp.com/send?phone=+5511976626886&text=Olá, gostaria de obter informações sobre as aulas de lutas da Academia New Fit %26 Fight, a melhor academia de lutas da região"
					(click)="goHaylie('regclick')">
					<img style="width: 63px; margin: 0px -3px; box-shadow: 1px 2px 2px #222; border-radius: 100%;" 
							src="../assets/img/social/whatsapp-circle.png" /></a>
				</li>
			</ul>
		</div>

	</div>
</div>