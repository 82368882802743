import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// <!-----> shoot with haylie's user token 
import { eResponse, ePicture, eLead, eAgenda, eClick } from './entities'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class HaylieService {
  shootUrl = '';
  testget = eResponse;

  server = 'http://haylie.bosswebapps.net/';
  endpoint_gallery = 'api/content/getgallery?p_bot=wanda&p_filter=gallery&p_param=clientrequest_nf';
  endpoint_lead = 'api/player/lead?p_bot=wanda';
  endpoint_agenda_get = 'api/agenda/getall?p_bot=wanda&p_param=clientrequest_nf';
  endpoint_agenda_set = 'api/agenda/save?p_bot=wanda&p_param=clientrequest_nf&';
  endpoint_class_gettoday = 'api/class/gettoday?p_bot=wanda';
  endpoint_class_get = 'api/class/getall?p_bot=wanda'; 
  endpoint_click_set = 'api/content/SaveClick?p_bot=wanda&p_param=clientrequest_nf';

  constructor(private http: HttpClient) { }

get_classes(p_day:string): Promise<any[]> {
  let l_params = "&p_param=clientrequest_nf";
  this.shootUrl = this.server + this.endpoint_class_get + l_params;

  return this.http
  .get<any[]>(this.shootUrl, httpOptions)
  .pipe(
    map((response: any) => response.Value)
  )
  .toPromise()

}
get_classestoday(p_day:string): Promise<any[]> {
  let l_params = "&p_param=clientrequest_nf";
  this.shootUrl = this.server + this.endpoint_class_gettoday + l_params;

  return this.http
  .get<any[]>(this.shootUrl, httpOptions)
  .pipe(
    map((response: any) => response.Value)
  )
  .toPromise()

}
get_agenda(): Promise<eAgenda[]> {

  this.shootUrl = this.server + this.endpoint_agenda_get;

  return this.http
  .get<eAgenda[]>(this.shootUrl, httpOptions)
  .pipe(
    map((response: any) => response.Value)
  )
  .toPromise()

}
/*: get gallery full list */ 
get_gallery(): Promise<ePicture[]> {
  this.shootUrl = this.server + this.endpoint_gallery;

  return this.http
  .get<ePicture[]>(this.shootUrl, httpOptions)
  .pipe(
    map((response: any) => response.Value)
  )
  .toPromise()

}
send_lead(p_lead: eLead):void {

  var l_params = "&p_name=" + p_lead.name + "&p_birth=" + p_lead.birth + "&p_mail=" + p_lead.mail + "&p_phone=" + p_lead.phone;
  
  this.shootUrl = this.server + this.endpoint_lead + l_params;
  
  let trythis = this.http
  .get(this.shootUrl, httpOptions)
  .pipe(
    map((response: any) => response.Value)
  )
  .toPromise();

  console.log('lead test>' + trythis);

}
send_agenda(p_agenda: eAgenda):void {
  ///api/agenda/save?p_bot=wanda&p_skill=thay&p_dateto=2020-12-07&p_time=1930&p_padawan=Sample&p_device=2&p_param=inject
  var l_params = "p_skill=" + p_agenda.skill + "&p_dateto=" + p_agenda.dateto;
  l_params += "&p_time=" + p_agenda.time + "&p_padawan=" + p_agenda.padawan;
  l_params += "&p_device=2";

  this.shootUrl = this.server + this.endpoint_agenda_set + l_params;
  
  let trythis = this.http
  .post(this.shootUrl, httpOptions)
  .pipe(
    map((response: any) => response.Value)
  )
  .toPromise();

  console.log('agenda test>' + trythis);
  
}

send_click(p_agenda: eClick):void {
  ///api/agenda/save?p_bot=wanda&p_skill=thay&p_dateto=2020-12-07&p_time=1930&p_padawan=Sample&p_device=2&p_param=inject
  var l_params = "";

  this.shootUrl = this.server + this.endpoint_click_set + l_params;
  
  let trythis = this.http
  .post(this.shootUrl, httpOptions)
  .pipe(
    map((response: any) => response.Value)
  )
  .toPromise();

  console.log('agenda test>' + trythis);
  
}

/**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
private log(message: string) {
  //this.messageService.add('ComicService: ${message}');
}

}
