<div id="block-map" class="content box">
    <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon>
    <div class="splitter"></div>
    
    <img src="../assets/img/pin-map.png" style="width: 52px; ">
    <p class="txt-label">Av. Santa Catarina 2005 - Vila Mascote</p>

    <div id="mymap" 
      style="width: 95%; margin: 9px 2.5%; height: 420px; position: relative; overflow: hidden;">

        <google-map
        id="elementmap" 
        height="420px"
        width="100%"
        [zoom]="zoom"
        [center]="center"
        [options]="options"
        >  
              <map-marker
              [position]="marker.position"
              [label]="marker.label"
              [title]="marker.title"
              [options]="marker.options"
            >
            </map-marker>

      </google-map>

    </div>

    <div style="text-align: center; position: relative; top: 9px;">
      <ul class="socials">
        <li>
          <a target="_blank" href="https://www.instagram.com/Academianewfitefight">
            <img style="width: 63px;" src="../assets/img/social/instagram.png" />
            <span></span>
          </a>
        </li>
        <li style="margin-left: 9px;">
          <a target="_blank" href="https://www.facebook.com/Academia-New-Fight-677449039130834">
            <img style="padding: 12px;" src="../assets/img/social/facebook-f.png" />
            <span></span>
          </a>
        </li>
        <li style="float: right;">
          <a target="_blank" 
              href="https://api.whatsapp.com/send?phone=+5511976626886&text=Olá, gostaria de obter informações sobre as aulas de lutas da Academia New Fit %26 Fight, a melhor academia de lutas da região"
              (click)="goHaylie('regclick')">
            <img style="width: 54px;" src="../assets/img/social/whatsapp-splash.png" />
            <span></span>
          </a>
        </li>
      </ul>
    </div>
    

</div>