import { Component, OnInit } from '@angular/core';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-block-bwa',
  templateUrl: './block-bwa.component.html',
  styleUrls: ['./block-bwa.component.css']
})
export class BlockBwaComponent implements OnInit {
  boxFAIcon = faBolt;

  constructor() { }

  ngOnInit(): void {
  }

}
