<div id="block-octa" class="content box dark">
    <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon>
    <div class="splitter"></div>

    <!--- img / logo --->
    <!-- remenber neuropol & spaceage--->
    <h1 class="title">OCTA</h1>
    <p class="sub">WARRIORS<br/>ONLY</p>

    <a href="http://octa.academianewfight.com.br/" target="_blank" >
        <img class="octasvg" src="../assets/svg/octagon-efefef.svg" 
					style="opacity: 1; width: 36px; margin-top: 30px;" />
    </a> 

    <button id="btn_goocta" class="btn-splatter" routerLink="goocta">PORTAL DO ALUNO</button>


</div>