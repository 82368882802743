import { Component, OnInit } from '@angular/core';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

import { Router } from '@angular/router'

@Component({
  selector: 'app-block-lead',
  templateUrl: './block-lead.component.html',
  styleUrls: ['./block-lead.component.css']
})
export class BlockLeadComponent implements OnInit {
  boxFAIcon = faUserEdit;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  goPage():void {
    this.router.navigateByUrl('goprehreg');

  }

}
