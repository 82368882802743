<div *ngIf="page_status">
    <div class="modal-bg"></div>
    <div id="page_prehreg"  class="modal">
        <img class="logo" src="../assets/img/logo/nftxt_on.png" />
        <a class="close" routerLink="/">{{ "< VOLTAR" }}</a>
        <br/><br/><br/>

        <div *ngIf="!loading_status">
            <fa-icon [icon]="boxFAIcon" class="pageicon"></fa-icon>
            <br/>
            <h1 class="title">PRÉ-CADASTRO</h1>
            <br/>
            <p class="sub">Faça seu pré-cadastro<br/>
                e garanta seu <b style="color: #000">desconto na matrícula</b></p>
            <br/>
            <div class="frm">
                <input class="inputtxt" type="text" #prehreg_nick name="nick" value="" placeholder="Nome" />
                <input class="inputtxt" type="number" maxlength="2" #prehreg_birth name="birth" value="" placeholder="Idade" />
                <input class="inputtxt" type="text" #prehreg_mail name="mail" value="" placeholder="Email" />
                <input class="inputtxt" type="number" #prehreg_phone name="phone" value="" placeholder="Telefone" />
                <!-- <a href="#" class="btn" id="btn-splatter">ENVIAR</a> -->
                <button class="btn-splatter" (click)="goHaylie()" 
                        style="top: 63px; font-size: 12px; letter-spacing: 3px;">
                    ENVIAR</button>
                <a href="#" class="btnReturn" routerLink="/">
                    VOLTAR</a>

            </div>
        </div>
        <div id="prehreg_loading" class="loading" style="opacity: 0; z-index: -1;">
            <fa-icon [icon]="boxFAIconResult" class="pageicon" style="top: 0;"></fa-icon>
            <br/>
            <h1 class="title">EMAIL ENVIADO</h1>
            <br/>
            <p class="sub">Pré-cadastro efetuado com <b style="color: #000">sucesso</b></p>
            <p class="sub">Entraremos em contato<br/> para confirmar sua <br/>aula experimental</p>
            <p class="sub">Obrigado<br/><br/><br/></p>
            <br/>
            <div style="float: left; position: relative; top: 90px; z-index: 999999999999;
                                text-align: center; width: 100%;">
                <img src="../assets/img/gif/loading.gif" />
            </div>

        </div>

    </div>    
</div>    