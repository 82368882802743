import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleMapsModule } from '@angular/google-maps'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BlockBwaComponent } from './block-bwa/block-bwa.component';
import { FooterComponent } from './footer/footer.component';
import { BlockAgendaComponent } from './block-agenda/block-agenda.component';
import { BlockGalleryComponent } from './block-gallery/block-gallery.component';
import { BlockSocialsComponent } from './block-socials/block-socials.component';
import { BlockLeadComponent } from './block-lead/block-lead.component';
import { BlockMapComponent } from './block-map/block-map.component';
import { PageAgendaComponent } from './page-agenda/page-agenda.component';
import { BlockOctaComponent } from './block-octa/block-octa.component';
import { PagePrehregComponent } from './page-prehreg/page-prehreg.component';
import { PageGalleryComponent } from './page-gallery/page-gallery.component';
import { PageClassesComponent } from './page-classes/page-classes.component';
import { BlockClassesComponent } from './block-classes/block-classes.component';
import { BlockAdsenseComponent } from './block-adsense/block-adsense.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    BlockBwaComponent,
    FooterComponent,
    BlockAgendaComponent,
    BlockGalleryComponent,
    BlockSocialsComponent,
    BlockLeadComponent,
    BlockMapComponent,
    PageAgendaComponent,
    BlockOctaComponent,
    PagePrehregComponent,
    PageGalleryComponent,
    PageClassesComponent,
    BlockClassesComponent,
    BlockAdsenseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    FontAwesomeModule, 
    GoogleMapsModule, 
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
