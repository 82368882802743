import { Component, OnInit } from '@angular/core';

import { faGem } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-block-octa',
  templateUrl: './block-octa.component.html',
  styleUrls: ['./block-octa.component.css']
})
export class BlockOctaComponent implements OnInit {
  //boxFAIcon = faUserAstronaut;
  boxFAIcon = faGem;

  constructor() {  }

  ngOnInit(): void {

  }

}
