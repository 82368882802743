import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckDouble, faClock } from '@fortawesome/free-solid-svg-icons';
import { eClass } from '../services/entities';
import { GsapService } from '../services/gsap.service';
import { HaylieService } from '../services/haylie.service';
import { MorganaService } from '../services/morgana.service';

@Component({
  selector: 'app-page-classes',
  templateUrl: './page-classes.component.html',
  styleUrls: ['./page-classes.component.css']
})
export class PageClassesComponent implements OnInit {
  boxFAIcon = faClock;
  page_status : boolean = false; 
  loading_status : boolean = false; 

  model_classes : eClass[] = [];
  // model_classes_jiu : eClass[] = [];
  // model_classes_jiu : eClass[] = [];
  // model_classes_jiu : eClass[] = [];
  model_base_days: string[] = ["segunda", "terça", "quarta", "quinta", "sexta", "sábado", "domingo"];
  model_base_skills: string[] = ["jiu", "thay", "boxe"];
  model_search_skill = '';
  model_search_day = '';

  constructor(
    private route: ActivatedRoute,
    private haylie: HaylieService,
    private morgana: MorganaService,
    private router: Router, 
    private gsap: GsapService
  ) { }

  // ngOnChanges() { 
  //   this.content_refresh();
  // }
  ngOnInit(): void {
    this.model_search_skill = '';
    this.model_search_day = '';
    this.model_search_skill = 'jiu';
    this.model_search_day = this.morgana.getdayofweek(new Date().getUTCDay().toString());
  
    this.haylie.get_classes("").then(this.catch_classes);
    this.page_status = true;
    }

  private catch_classes = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    this.model_classes = [];
    this.model_classes = l_response.classes;
    //this.model_classes_result = this.model_classes;

    return this.model_classes;
  }
  filterClasses(p_skill:string, p_day:string): any[] {
    let result : eClass[] = [];  
    //this.model_classes_result = [];

    if (p_skill.length > 0)
      return this.model_classes.filter(x => x.skill === p_skill && x.day === this.model_search_day);
    else 
      return this.model_classes.filter(x => x.day === this.model_search_day);
    
  
  }
  select_change(p_select:string, p_val:string):void {
    switch (p_select) { 
      case "search": this.model_search_skill = p_val; break;
      case "searchday": 
        this.model_search_day = p_val; 
        console.log('p_val> ' + p_val);
        console.log('model_search_day> ' + this.model_search_day);
        break;
    }
    //this.frmskill.focus();
  }

}
