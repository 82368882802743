import { Component, OnInit, ViewChild } from '@angular/core';
import { faMapSigns } from '@fortawesome/free-solid-svg-icons';
import { eClick } from '../services/entities';
import { HaylieService } from '../services/haylie.service';

// <script>
//       function initMap() {
// 	  //-23.6516466,-46.6611304
//           var uluru = { lat: -23.6516466, lng: -46.6611304 };
//         var map = new google.maps.Map(document.getElementById('home_map'), {
//           zoom:15,
//           center: uluru
//         });
//         var marker = new google.maps.Marker({
//           position: uluru,
//           map: map
//         });
//       }
//     </script>

// @ViewChild('elementmap')

@Component({
  selector: 'app-block-map',
  templateUrl: './block-map.component.html',
  styleUrls: ['./block-map.component.css']
})
export class BlockMapComponent implements OnInit {
  boxFAIcon = faMapSigns;

  ////-23.6516466,-46.6611304
  zoom = 13
  center: google.maps.LatLngLiteral = { lat: -23.6516109, lng: -46.6588789 }
  options: google.maps.MapOptions = {
    mapTypeControl: false,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    
    minZoom: 8,
  }
  marker = {
    position: {
     lat: this.center.lat,
     lng: this.center.lng,
   },
   label: {
     color: 'black',
     text: 'New Fight',
   },
   title: 'New Fight',
   options: { animation: google.maps.Animation.BOUNCE },
  };

  constructor(private haylie: HaylieService) { }
/*-23.6516109 
  -46.6588789*/
  ngOnInit(): void {
    this.center = {
      lat: -23.6516109,
      lng: -46.6588789,
    }

    


  }

  goHaylie(p_param:string): void{

    switch (p_param) {
      case "regclick":

        var click = new eClick();

        this.haylie.send_click(click); 
        break;
    }

  }

}
