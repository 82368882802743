import { Component, OnInit } from '@angular/core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons'
import { ePicture } from '../services/entities';
import { HaylieService } from '../services/haylie.service';



@Component({
  selector: 'app-block-gallery',
  templateUrl: './block-gallery.component.html',
  styleUrls: ['./block-gallery.component.css']
})
export class BlockGalleryComponent implements OnInit {
  boxFAIcon = faCameraRetro;
  page_status: boolean = false;
  model_pics: ePicture[] = [];

  constructor(private haylie: HaylieService) { }

  ngOnInit(): void {
    this.get_gallery();
    this.page_status = true;
  }

  get_gallery(): void {
    this.haylie.get_gallery().then(this.load_gallery);
  }
  private load_gallery = (p_response: any) =>  {
    var l_response = JSON.parse(p_response);
    //var jobj = JSON.parse(l_response);
    //console.log('response> ' + l_response.pictures[1].name);
    this.model_pics = l_response.pictures;
    this.model_pics = this.model_pics.filter(x => x.alive == 2 || x._file_alive == 2);
    return this.model_pics;
   
  }

}
