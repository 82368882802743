import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MorganaService {

  constructor() { }

  getmonthname(p_month: string, p_full: boolean): string {
    var result = ""
    switch (p_month) {
      case "1": result= "Janeiro"; break;
      case "2": result= "Fevereiro"; break;
      case "3": result= "Março"; break;
      case "4": result= "Abril"; break; 
      case "5": result= "Maio"; break;
      case "6": result= "Junho"; break;
      case "7": result= "Julho"; break;
      case "8": result= "Agosto"; break;
      case "9": result= "Setembro"; break;
      case "10": result= "Outubro"; break;
      case "11": result= "Novembro"; break;
      case "12": result= "Dezembro"; break;
    }

    if (!p_full) result = result.substr(0, 3);

    return result;
  }

  getdayofweek(p_day: string): string {
    var result = ""
    switch (p_day) {
      case "0": result= "domingo"; break;
      case "1": result= "segunda"; break;
      case "2": result= "terça"; break;
      case "3": result= "quarta"; break; 
      case "4": result= "quinta"; break;
      case "5": result= "sexta"; break;
      case "6": result= "sábado"; break;
    }

    return result;
  }

}
