import { Component, OnInit } from '@angular/core';

import { faCalendarCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { eClass } from '../services/entities';

@Component({
  selector: 'app-block-agenda',
  templateUrl: './block-agenda.component.html',
  styleUrls: ['./block-agenda.component.css']
})
export class BlockAgendaComponent implements OnInit {
  boxFAIcon = faCalendarCheck;
  boxFAIconDouble = faCheckDouble;

  constructor() { }

  ngOnInit(): void {
  }

}
