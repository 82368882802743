<div *ngIf="page_status">
    <div class="modal-bg"></div>
    <div id="page_gallery" class="modal">
        <img class="logo" src="../assets/img/logo/nftxt_on.png" />
        <a class="close" routerLink="/">{{ "< VOLTAR" }}</a>
        <br/><br/><br/>

        <fa-icon [icon]="boxFAIcon" class="pageicon"></fa-icon>
        <h1 class="title">GALERIA</h1>
        <p class="sub">#everydayanewfight</p>

        <div style="float: left; overflow: hidden; height: auto; min-height: 420px; max-height: 700px; overflow-y: scroll">
            <ul *ngIf="model_pics" style="padding-bottom: 300px;">
                <li *ngFor="let p of model_pics">
                    <a *ngIf="p.name != 'init'" class="col-1-4" (click)="goBack"> 
                        <img src="{{ 'http://haylie.bosswebapps.net/gallery/' + p.name }}" width="98%" />
                    </a>
                    
                </li>
            </ul>
            <div *ngIf="!model_pics" style="float: left; position: relative; top: 90px; z-index: 999999999999;
                                text-align: center; width: 100%;">
                <img src="../assets/img/gif/loading.gif" />
            </div>

        </div>
    </div>
</div>

