import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { GsapService } from '../services/gsap.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private router: Router, private gsap:GsapService) { }

  ngOnInit(): void {

  }
  sidebar_trg(p_action:string):void {
    this.gsap.anima_trg("sidebar_" + p_action);
  }
  goPage(p_route:string):void {
    this.gsap.anima_trg("sidebar_close");

    if (p_route == "gotalk")
      this.gsap.anima_trg("scrollto", 2752);
    else
      setTimeout(() => { this.router.navigateByUrl(p_route) }, 360);

  }

}
