<div id="block-adsense" class="content box" style="display: none">
    <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon> 
    <div class="splitter"></div>    
    
    <p style="color: #999; font-size: 9px; text-align: right; margin: 0; margin-right: 3px; 
        letter-spacing: 1px; margin-top: -18px;">publicidade</p>
    <br>

    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3677086781415159"
     crossorigin="anonymous"></script>

</div>