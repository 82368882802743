import { Component } from '@angular/core';
import { eClick } from './services/entities';
import { GsapService } from './services/gsap.service'
import { HaylieService } from './services/haylie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'atena';

  constructor(private _gsapService: GsapService, private haylie: HaylieService) { } 

  ngOnInit () {
    this._gsapService.init_setup();

    this._gsapService.anima_full();


  }
  goHaylie(p_param:string): void{

    switch (p_param) {
      case "regclick":

        var click = new eClick();

        this.haylie.send_click(click); 
        break;
    }

  }
}
