import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageAgendaComponent } from './page-agenda/page-agenda.component'
import { PagePrehregComponent } from './page-prehreg/page-prehreg.component'
import { PageGalleryComponent } from './page-gallery/page-gallery.component'
import { PageClassesComponent } from './page-classes/page-classes.component'



const routes: Routes = [
  { path: 'goagenda', component: PageAgendaComponent }, 
  { path: 'goprehreg', component: PagePrehregComponent }, 
  { path: 'gogallery', component: PageGalleryComponent }, 
  { path: 'goclasses', component: PageClassesComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
