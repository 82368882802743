<div id="block-bwa" class="content box">
    <fa-icon [icon]="boxFAIcon" class="ico"></fa-icon>
    <div class="splitter"></div>
    
    <p style="color: #999; font-size: 9px; text-align: right; margin: 0; margin-right: 3px; 
        letter-spacing: 1px; margin-top: -18px;">powered by</p>
    <br>
    <a href="http://bosswebapps.net" id="bwaicon" target="_blank">
        <img src="../assets/img/shortcut-boss.png" style="width: 70px; position: relative; top: 3px; border-radius: 9px;"></a>
    <br>
    <a href="http://bosswebapps.net" id="bwalogo" target="_blank">
        <img src="../assets/img/logo-boss.png" style="width: 160px; position: relative; top: 9px; border-radius: 0px;"></a>
    <br> 
    
    <p class="slogan">BUILD THE FUTURE</p>

</div>